import React from 'react'
import imglog from '../images/question.svg'
import imglog1 from '../images/news.svg'
import imglog2 from '../images/XMLID_1974_.svg'
import InsightConsol from './InsightConsol'

const ConsolCards = () => {
  return (
    <>
     <div className='consol-card-heading'>
        <h2>Insights</h2>
        <div className="consol-whole-cards">
        <div className="row">
    <div className="col-4">
        <div className="card">
            <div className="cardimg">
                <img src={imglog} alt="" className='imgcard'/>
            </div>
            <div className="cardtext">
                <h5>123</h5>
                <p>Total Questions</p>
            </div>
        </div>
    </div>
    <div className="col-4">
        <div className="card">
            <div className="cardimg">
                <img src={imglog1} alt="" className='imgcard'/>
            </div>
            <div className="cardtext">
                <h5>45</h5>
                <p>Total Lessons</p>
            </div>
        </div>
    </div>
    <div className="col-4">
        <div className="card">
            <div className="cardimg">
                <img src={imglog2} alt="" className='imgcard'/>
            </div>
            <div className="cardtext">
                <h5>56%</h5>
                <p>Average Grade</p>
            </div>
        </div>
    </div>
</div>
        </div>

    </div>
    <InsightConsol/>
    </>
   
  )
}

export default ConsolCards