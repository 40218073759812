import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getClassessAsyncThunk, getStaffClassessAsyncThunk } from "../../redux/pagesSlices/classesSlice";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { setClass } from "../../redux/pagesSlices/authSlice";
import spaceAvatar from "../../images/stripe-2.png";
import plusBlack from "../../images/plus-black.svg";
import { parseUrlFromModel } from "../../helpers/asset";
import { Button } from "react-bootstrap";
import add_icon from "../../images/add.svg";
import { IoMdArrowDropdown } from "react-icons/io";
import Classes from "../../images/Classes.svg";
import { countUserEvent } from "../../utils/utlis";
import { getOrganization } from "../../constants";
import { ApiRequests } from "../../service/ApiRequests";

// Custom Toggle Component
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// Custom Menu Component with Search
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

function SwitchSpaces() {
  const d = useDispatch();
  const classes = useSelector((s) => s.classes.Classes);
  console.log("🚀 ~ SwitchSpaces ~ classes:", classes);
  const selectedSpace = useSelector((s) => s.auth.class);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectClass = (item) => {
    selectClass(item);
    setIsOpen(false);
  };

  const selectClass = (item) => {
    d(setClass(item));
    setSelectedClass(item);
  };

  useEffect(() => {
    if (!selectedSpace && classes.results && classes.results.length > 0) {
      setSelectedClass(classes.results[0]);
      d(setClass(classes.results[0]));
    }
  }, [classes.results, d]);


  useEffect(() => {
    const params = {
      limit: 1000,
      page: 1,
      populate: "image,user_id",
    };
    const organization = getOrganization();
    const getClasses = async () => {
      const memberSip = await ApiRequests.getOrganizationMembership(
        organization?.tenantId
      );
      d(
        memberSip?.data.some((i) => i?.name === "Admin")
          ? getClassessAsyncThunk(params)
          : getStaffClassessAsyncThunk(params)
      );
    };
    getClasses()
  }, [d]);

  return (
    <>
      <div className="section-2">
        <div className="classes-icon"></div>
        <div className="dropdown-sec">
          <Dropdown show={isOpen} onToggle={toggleDropdown}>
            <Dropdown.Toggle
              onClick={toggleDropdown}
              id="dropdown-basic"
              className="create-btn"
            >
              <img
                src={
                  parseUrlFromModel(selectedSpace?.image) ||
                  require("../../images/logo.png")
                }
                alt="avatar"
                style={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "50px",
                }}
              />
              {selectedSpace ? selectedSpace.name : "Select Space"}
              <div className="dropdown-icon">
                <IoMdArrowDropdown />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="menuItems">
                <div className="dropdown-heading">
                  <img
                    src={
                      parseUrlFromModel(selectedSpace?.image) ||
                      require("../../images/logo.png")
                    }
                    alt="avatar"
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50px",
                    }}
                  />
                  <strong>
                    {" "}
                    {selectedSpace ? selectedSpace.name : "Select Space"}
                  </strong>
                </div>
                <div className="mainItems">
                  {classes &&
                    classes.results &&
                    Array.isArray(classes.results) &&
                    classes.results.length > 0 ? (
                    classes.results.map((item, index) => (
                      <div
                        className="item"
                        key={index}
                        onClick={() => {
                          countUserEvent("select_space", {
                            eventName: "select_space",
                          });
                          handleSelectClass(item);
                        }}
                      >
                        <img
                          src={
                            parseUrlFromModel(item?.image) ||
                            require("../../images/logo.png")
                          }
                          alt="avatar"
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "50px",
                          }}
                        />
                        <p>{item.name}</p>
                      </div>
                    ))
                  ) : (
                    <p>No spases available</p>
                  )}
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default SwitchSpaces;
