import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import filter_alt from "../../images/filter_alt.svg";
import studentImg from "../../images/studentImg.svg";
import { LuExternalLink } from "react-icons/lu";
import { LuChevronRight } from "react-icons/lu";
import { LuChevronLeft } from "react-icons/lu";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { getUsersAsyncThunk } from "../../redux/pagesSlices/userSlice";
import { classSpace, redirect_uris } from "../../constants";
import NoDataFound from "../NoDataFound";
import Loadingdata from "../loadingdata";
import { Padding } from "@mui/icons-material";
const AddDictionary = () => {
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.AddDictionary
  );
  const state = useSelector((state) => state.model?.modelState?.AddDictionary);
  const d = useDispatch();
  const [search, setSearch] = useState("");
  const customToken = localStorage.getItem("custom-token");
  const Users = useSelector((state) => state.user?.users);
  const loading = useSelector(
    (state) => state?.user?.loadings?.getUsersAsyncThunk
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let params = {
      role: "User",
      page: currentPage,
      limit: rowsPerPage,
    };

    if (state) {
      d(getUsersAsyncThunk(params));
    }
  }, [d, currentPage, rowsPerPage, state]);

  const closeModel = () => {
    d(handleModel({ model: "AddDictionary", state: false }));
    setSearch("");
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(Users?.totalResults / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const filteredUsers = Users?.results?.filter((items) =>
    items.given_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <Modal
        className="directoryModal"
        show={state}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Directory
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-students mb-2 d-flex">
            <img src={filter_alt} alt="" className="img-fluid mx-2" />
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Filter Students"
            />
          </div>
          <div className="student-header mb-2 d-flex align-items-center row">
            <div className="col-6">
              <p className="m-0">Name</p>
            </div>
            <div className="col-3">
              <p className="m-0">Grade</p>
            </div>
            <div className="col-3 d-flex justify-content-center">
              <p className="m-0">View</p>
            </div>
          </div>

          {loading ? (
            <Loadingdata customHeight={{ height: "35vh" }} />
          ) : (
            <ul className="list-unstyled">
              {filteredUsers?.length === 0 ? (
                <NoDataFound
                  customHeight={{
                    padding: "0px 0px !important",
                    marginBottom: "10px",
                  }}
                />
              ) : (
                filteredUsers?.map((items, index) => (
                  <li key={index} className="w-100">
                    <div className="data-wrapper d-flex align-items-center">
                      <div className="row w-100 align-items-center">
                        <div className="col-6 d-flex align-items-center">
                          <div className="student_img_wrapper d-flex align-items-center mx-3">
                            <img
                              src={items.photoURL}
                              className="img-fluid me-3 rounded-circle"
                              style={{ width: "36px", height: "36px" }}
                              alt={items.given_name}
                            />
                            <span className="name">{items.given_name}</span>
                          </div>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                          <p className="grade m-0 px-2">{items.grade}</p>
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-center  view-icon-wrapper">
                          <a
                            href={`${redirect_uris.People}users/${
                              items.user_id
                            }?token=${customToken}&space_id=${
                              classSpace().class_id
                            }`}
                            target="_blank"
                          >
                            <LuExternalLink className="fs-4 text-black ms-5 maxi-icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <div className="invisible d-none d-lg-block">
            asdasdasasdasdsdfsdfdasdads
          </div>
          <div className="d-flex align-items-center">
            <span>Rows per page:</span>
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="mx-3 mx-sm-5"
              name="rowsPerPage"
              id="pages"
            >
              <option value="10">10 </option>
              <option value="12">12</option>
              <option value="48">48</option>
              <option value="50">50</option>
            </select>
            <span className="ms-2">
              {`${(currentPage - 1) * rowsPerPage + 1} - ${
                currentPage * rowsPerPage > Users?.totalResults
                  ? Users?.totalResults ?? Users?.totalResults
                  : currentPage * rowsPerPage
              } of `}{" "}
              {Users?.totalResults}
            </span>
          </div>
          <div className="d-flex align-items-center me-3">
            <a
              onClick={handlePreviousPage}
              href="#"
              className="text-decoration-none me-2"
            >
              <LuChevronLeft className="fs-4 text-dark" />
            </a>
            <a
              onClick={handleNextPage}
              href="#"
              className="text-decoration-none"
            >
              <LuChevronRight className="fs-4 text-dark" />
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddDictionary;
