import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../../helpers/asset";
import { Formik } from "formik";
import ErrorMassage from "../ErrorMassage";
import CustomButton from "../customComponents/CustomButton";
import * as yup from "yup";
import { createClassesAsyncThunk } from "../../redux/pagesSlices/classesSlice";
import { toast } from "react-toastify";

const CreatSpace = () => {
  const [image, setImages] = useState(null);
  const modelArgs = useSelector((state) => state.model?.modelArgs?.CreatSpace);
  const state = useSelector((state) => state.model?.modelState?.CreatSpace);
  const oldData = modelArgs?.args?.classes;
  const d = useDispatch();
  const openContent = (setFieldValue) => {
    d(
      handleModel({
        model: "contentModel",
        state: true,
        args: {
          type: "image",
          image: null,
          callBack: (e) => {
            setImages(e);
            setFieldValue("image", e?.id);
          },
        },
      })
    );
  };

  const createClassHandler = (data) => {
    d(
      createClassesAsyncThunk({
        data,
        callBack: () => {
          toast.success("Create Classes Successfully!");
          closeModel();
          setImages(null);
        },
      })
    );
  };
  const closeModel = () => {
    d(handleModel({ model: "CreatSpace", state: false }));
  };

  const ClassSchemaIn = yup.object().shape({
    name: yup.string().required("Name is Required"),
    image: yup.string().required("Image is Required"),
  });

  const initialValues = {
    name: "",
    image: "",
  };

  return (
    <>
      <Modal
        className="index-create-model"
        show={state}
        size="md"
        onHide={closeModel}
        centered
      >
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={ClassSchemaIn}
            onSubmit={createClassHandler}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                <div className="m-header">
                  <h3>{oldData ? "Update Classes" : "Create Classes"}</h3>
                  <i className="fa-solid fa-xmark" onClick={closeModel}></i>
                </div>
                <div className="m-body">
                  <div className="create-course-section">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <div
                        className="add-img-card"
                        onClick={() => openContent(setFieldValue)}
                      >
                        {image ? (
                          <img
                            className="u-img"
                            src={parseUrlFromModel(image)}
                            alt="#"
                          />
                        ) : (
                          <>
                            <div className="plus">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M11.5 13H5.5V11H11.5V5H13.5V11H19.5V13H13.5V19H11.5V13Z"
                                  fill="#2181FA"
                                />
                              </svg>
                            </div>
                            <h5>Add image</h5>
                            <p>JPG, PNG. under 2MB</p>
                          </>
                        )}
                        <ErrorMassage
                          error={errors.image}
                          visible={touched.image}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="indexCreateName">
                      <Form.Control
                        type="name"
                        placeholder="Class name"
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                        value={values.name}
                        id="indexCreateName"
                        name="indexCreateName"
                      />
                      <ErrorMassage
                        error={errors.name}
                        visible={touched.name}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="m-footer">
                  <button className=" btn btn-cancel" onClick={closeModel}>
                    Cancel
                  </button>
                  <CustomButton
                    loadingText={"Saving..."}
                    className={"btn btn-save"}
                    title={"Create"}
                    action={"createClassesAsyncThunk"}
                    reducer={"classes"}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatSpace;
