import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { RiShareBoxLine } from "react-icons/ri";
import batch1 from '../images/batch-1.png'
import batch2 from '../images/batch-2.png'
import batch3 from '../images/batch-3.png'
import batch4 from '../images/batch-4.png'
import batch5 from '../images/batch-5.png'
import crown1 from '../images/crown-1.png'
import crown2 from '../images/crown-2.png'
import crown3 from '../images/crown-3.png'
import crown4 from '../images/crown-4.png'
import trophy1 from '../images/trophy-1.png'
import trophy2 from '../images/trophy-2.png'
import trophy3 from '../images/trophy-3.png'
import trophy4 from '../images/trophy-4.png'
import { MdKeyboardArrowDown } from "react-icons/md";
// import Loadingdata from '../loadingdata';    
import Chart from '../screens/BarChart'

const InsightConsol = () => {
    // const topRankedUsers = getUsers?.slice(0, 3)
    // console.log("🚀 ~ InsightsActivities ~ topRankedUsers:", topRankedUsers)

    // // console.log("topRankedUsers",topRankedUsers);
    // function formatTime(seconds) {
    //     const totalSeconds = Math.floor(seconds);
    //     const hrs = Math.floor(totalSeconds / 3600);
    //     const mins = Math.floor((totalSeconds % 3600) / 60);
    //     const secs = totalSeconds % 60;

    //     return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    // }
    return (
        <div className='activity-page'>

            <Row>
                <Col md={6} className='mb-4'>


                    <div className='card-div'>
                        <div className='student-activity-1'>
                            <h5> Students Activity</h5>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #DEE1E6', display: 'flex', alignItems: 'center', color: '#2D2F31', }}>
                                        Month
                                        <MdKeyboardArrowDown />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1" >Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2" >Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3"  >Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className='main-chart'>
                            <Chart />
                        </div>
                    </div>




                </Col>


                <Col md={6} className="mb-4">
                    <div className="card-div">
                        <div className="student-activity">
                            <h5>Top Students</h5>
                            <a href="/">
                                <RiShareBoxLine />
                            </a>
                        </div>
                        <div className="main-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: "10%" }}>Rank</th>
                                        <th style={{ width: "" }}>Name</th>
                                        <th style={{ width: "" }}>Points</th>
                                        <th>Time spent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="main-batch">
                                                <img
                                                    src={batch4 }
                                                    alt="Rank Badge"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="batch">
                                                <img src={batch4} alt="User Avatar" />
                                                <p>Random</p>
                                            </div>
                                        </td>
                                        <td>694</td>
                                        <td>34:51:06</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>




                <Col md={6} className='mb-4'>
                    <div className='card-div'>
                        <div className='student-activity'>
                            <h5>Mastery</h5>
                            <a href="/"> <RiShareBoxLine /></a>
                        </div>
                        <div className='mastery'>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={crown1} alt="img" />
                                </div>
                                <h4>12%</h4>
                                <p>inter</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={crown2} alt="img" />
                                </div>
                                <h4>45%</h4>
                                <p>Competents</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={crown3} alt="img" />
                                </div>
                                <h4>54%</h4>
                                <p>Proficient</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={crown4} alt="img" />
                                </div>
                                <h4>44%</h4>
                                <p>Expert</p>
                            </div>

                        </div>
                    </div>

                </Col>
                <Col md={6} className='mb-4'>
                    <div className='card-div'>
                        <div className='student-activity'>
                            <h5>Trophies Earned | 199</h5>
                            <a href="/"> <RiShareBoxLine /></a>
                        </div>
                        <div className='mastery'>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={trophy1} alt="img" />
                                </div>
                                <h4>88</h4>
                                <p>Bronze</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={trophy2} alt="img" />
                                </div>
                                <h4>12</h4>
                                <p>Gold</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={trophy3} alt="img" />
                                </div>
                                <h4>12</h4>
                                <p>Platinum</p>
                            </div>
                            <div className='main-crown'>
                                <div className='crown'>
                                    <img src={trophy4} alt="img" />
                                </div>
                                <h4>112</h4>
                                <p>Silver</p>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default InsightConsol
