import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FileUploader } from "react-drag-drop-files";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

// import { getContentsAsyncThunk } from "../../../redux/pagesSlices/contentSlice";
import ImagesList from "./imagesList";
import VideosList from "./VideosList";
import AudiosList from "./AudiosList";
import PdfsList from "./PdfsList.jsx";
import MyGallery from "./MyGallery";
// import { handleModel } from "../../../redux/layoutSlices/modelSlice";
import ProgressBar from "react-bootstrap/ProgressBar";

// import { createContentAsyncThunk } from "../../../redux/pagesSlices/contentSlice";
import { fileTypes } from "../../constants.js";
import { createContentAsyncThunk, getContentsAsyncThunk } from "../../redux/pagesSlices/contentSlice.js";
// import { createContentAsyncThunk } from "../../redux/pagesSlices/contentSlice.js";

function ContentModelBody({ closeModel }) {
  const [gellaryTab, setGellaryTab] = useState("all");
  const d = useDispatch();
  const contents = useSelector((s) => s?.contents?.contents) ?? {};
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState("upload");

  const [file, setFile] = useState([]);

  const handleChange = (e) => {
    setFile(e);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (file?.length) {
      for (let i = 0; i < file?.length; i++) {
        formData.append("files", file[i]);
      }
    } else {
      return;
    }
    d(
      createContentAsyncThunk({
        data: formData,
        callBack: () => {
          setTimeout(() => {
            setProgress(0);
          }, 1000);
          setFile([]);
        },
        options: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // const progress = (progressEvent.loaded / progressEvent.total) * 50;
            // console.log("progress::onUploadProgress:::", progress);
            // setProgress(progress);

            //
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("percentCompleted::", percentCompleted);
            setProgress(percentCompleted);

            //
          },
          onDownloadProgress: (progressEvent) => {
            const progress =
              50 + (progressEvent.loaded / progressEvent.total) * 50;
            console.log("progress:onDownloadProgress::", progress);
            setProgress(progress);
          },
        },
      })
    );
  };

  useEffect(() => {
    d(getContentsAsyncThunk());
  }, []);

  return (
    <div style={{borderRadius:'8px'}}>
      <div className="tabs-heading">
        <div>
          <h4
            style={{
              fontSize: 14,
              fontFamily: "sans-serif",
              fontWeight: "bold",
              letterSpacing: 1,
              color: "#36454F",
            }}
          >
            Media gallery
          </h4>
        </div>
        {closeModel && (
          <div onClick={closeModel} className="modal-close-btn">
            {/* <button style={{ paddingLeft: '25px', paddingRight: '25px', marginLeft: '-10px' }} className='btn btn-danger'>Close</button> */}
            <div className="model-icon">
              <i
                style={{ marginTop: "13px", fontWeight: 600, fontSize: 22 }}
                className="fa-thin fa-circle-xmark"
              ></i>
            </div>
          </div>
        )}
      </div>
      {/* <button onClick={() => modelsArgs?.setContent('wow')}>hahaha</button> */}
      <div className="library-tabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="upload" title="Upload">
            <form onSubmit={onSubmit}>
              <div style={{ height: 300 }} className={"uploader-drag-drop"}>
                <FileUploader
                  multiple={true}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              </div>
              <p className="model_paragraph">
                <ProgressBar now={progress} />
                {file && file?.length
                  ? `File name: ${Object.values(file)?.map(
                      (item, i) => `${i + 1} :  ${item?.name}`
                    )}`
                  : "Select or drop file to upload!"}
              </p>
              <button
                style={{ padding: "5px 30px" }}
                className="btn btn-primary"
                type="submit"
              >
                Upload
              </button>
            </form>
          </Tab>
          <Tab eventKey="image" title="Image" className="image-tab">
            <ImagesList _key={key} />
          </Tab>
          <Tab eventKey="video" title="Video" className="video-tab">
            <VideosList _key={key} />
          </Tab>
          <Tab eventKey="audio" title="Audio">
            <AudiosList _key={key} />
          </Tab>
          <Tab eventKey="pdf" title="PDF">
            <PdfsList _key={key} />
          </Tab>
          <Tab eventKey="icon" title="Icon">
            <div id=" getContentsAsyncThunk_icon_parent">
              <Form.Control
                type="email"
                placeholder="Search free icons from Noun Project"
                className="mb-3"
              />
              <div className="row">
                {[0, 1, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                  return (
                    <div className="col-lg-4" key={index}>
                      <div
                        style={{
                          background: "#F0F0F0",
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "40PX",
                        }}
                      >
                        <img
                          style={{
                            width: 60,
                            height: 60,
                            objectFit: "cover",
                          }}
                          src={require("../../images/heart-icon.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Tab>
          <Tab eventKey="gallery" title="Gallery" className="gellary-tab">
            <MyGallery />
          </Tab>
        </Tabs>
      </div>
      {/* {closeModel &&
                <div onClick={closeModel} className="modal-close-btn">
                    <button className='btn btn-primary mb-3'>Select</button>
                </div>
            } */}
    </div>
  );
}
export default React.memo(ContentModelBody);
