import React from 'react'
import imglog from '../images/Mask group.svg'
import imglog1 from '../images/profileicon.svg'
import imglog2 from '../images/Wrong.svg'
import imglog3 from '../images/help_center.svg'
import imglog4 from '../images/green circle.svg'


const ConsolHeader = () => {
  return (
    
    <div className='consol-header'>
           <div className="row">
    <div className="col-6">
        <div className="info1">
          <div className='proimg'><img src={imglog} alt="Mask group.svg" className='green-rounded-img'/></div>
          <div className='text1'>
            <h5>Welcome Kulthum 👋🏼</h5>
            <div className="text2">
                <p>You’re working in <span>Islamic Studies</span></p>
            </div>
          </div>


        </div>
    </div>
    <div className="col-6">
        <div className="icons">
            <div className="icon1">
          <img src={imglog1} alt="profileicon.svg" className='iconimg'/>
                <span>651</span>
            </div>
            <div className="icon1">
              <img src={imglog2} alt="Wrong.svg"  className='iconimg'/>
                <span>34</span>
            </div>
            <div className="icon1">
           <img src={imglog3} alt="help_center.svg"  className='iconimg' />
                <span>165</span>
            </div>
            <div className="icon2">
             <div className='capimg'>
                <img src={imglog4} alt="green circle.svg" className='green-circle'/>
             </div>
             <div className="inico2">
                <h5>4</h5>
             </div>

                
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default ConsolHeader