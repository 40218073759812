import React from 'react'
import imglog1 from '../images/person 1.svg'
import imglog2 from '../images/person 2.svg'
import imglog3 from '../images/person 3.svg'
import imglog4 from '../images/person 4.svg'
import imglog5 from '../images/open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg'
import SearchBar from './SearchBar'

const ConsolTable2 = () => {
  return (
    <div className='consol-table-page'>
         <div className="consol-card-heading mb-3">
          <h2>Students</h2>
        </div>
        <SearchBar  text='Not read'/>  
    <div className='consol-table'>
                        <table className='table2-of-consol'>
<thead>
<tr>
            <th>
            <div className="info">
            <span>Name</span>
        </div>
            </th>
            <th>
            <div className="info1">
            <span>Grade</span>
        </div>
            </th>
            <th>
            <div className="info">
            <span>View</span>
        </div>
        </th>
       </tr>
</thead>
         <tbody>
         <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog1} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>6</span>
            </div>
   </td>
   <td>
            <div className="info2">
            <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog2} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>5</span>
            </div>
   </td>
   <td>
            <div className="info2">
            <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog3} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>3</span>
            </div>
   </td>
   <td>
            <div className="info2">
            <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog4} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>4</span>
            </div>
   </td>
   <td>
            <div className="info2">
            <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
            </div>
   </td>
      </tr>

         </tbody>
            </table>
    </div>
    </div>
  )
}

export default ConsolTable2