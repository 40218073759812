import React from "react";
import { Skeleton } from "@mui/material";

export default function skeletonLoadingGrid() {
  return (
    <div>
      <div className="row">
        <div className="">
          <Skeleton
            variant="rectangular"
            height={150}
            animation="wave"
            style={{
              borderRadius: 8,
              padding: "15px",
              margin: "auto",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
