import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

// Start Favourite Slices
///////////////////////////////////////////////////

export const createFavouriteAsyncThunk = createAsyncThunk(
  "favourites/createFavouriteAsyncThunk",
  async ({ data, callBack }, { dispatch }) => {
    try {
      const response = await ApiRequests.createFavouriteRoute(data);
      // if (response.status === 200) {
      //   toast.success("Added Successfully!");
      // } else if (response.status === 204) {
      //   toast.success("Removed Successfully!");
      // }
      if (callBack) callBack();
    } catch (error) {
      console.error("Error creating favorite:", error);
      toast.error("Failed to update favorite!");
    }
    // Return the data unconditionally to ensure immediate update
    return data;
  }
);

export const getMyFavouriteRoutesAsyncThunk = createAsyncThunk(
  "favourites/getMyFavouriteRoutesAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyFavouriteRoute(params);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  story: null,
  assets: null,
  asset: null,
  favouriteRoutes: [],
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const favouriteSlice = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(createFavouriteAsyncThunk.pending, (state, action) => {
        const { route_name, route_url } = action.meta.arg.data;
        const isAlreadyExists = state.favouriteRoutes.some(
          (item) => item.route_name === route_name
        );
        if (isAlreadyExists) {
          state.favouriteRoutes = state.favouriteRoutes.filter(
            (item) => item.route_name !== route_name
          );
        } else {
          state.favouriteRoutes.push({ route_name, route_url });
        }
      })
      .addCase(getMyFavouriteRoutesAsyncThunk.fulfilled, (state, action) => {
        state.favouriteRoutes = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createFavouriteAsyncThunk,
            getMyFavouriteRoutesAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default favouriteSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  favouriteSlice.actions;
