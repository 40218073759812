import React from 'react'
import imglog from '../images/home icon.svg'
import imglog1 from '../images/starsicon.svg'
import imglog2 from '../images/paper.svg'
import imglog3 from '../images/inbox 2.svg'
import imglog4 from '../images/list.svg'
import imglog5 from '../images/support.svg'
import imglog6 from '../images/account.svg'
import imglog7 from '../images/Classes.svg'
import imglog8 from '../images/account_box_FILL0_wght400_GRAD0_opsz24 (1) 2.svg'
import imglog9 from '../images/profileicon.svg'
import imglog10 from '../images/Guardians.svg'
import imglog11 from '../images/supervisor.svg'
import imglog12 from '../images/ballot_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg'
import imglog13 from '../images/4in1icon.svg'
import imglog14 from '../images/digital_wellbeing_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg'
import imglog15 from '../images/Analytics.svg'
import imglog16 from '../images/Insights.svg'
import imglog17 from '../images/browse_activity_2.svg'
import imglog18 from '../images/Streams.svg'
import imglog19 from '../images/Grades.svg' 
import imglog20 from '../images/Report.svg'
import imglog21 from '../images/leaderboard.svg'
import imglog22 from '../images/rank.svg' 
import imglog23 from '../images/wysiwyg.svg'
import imglog24 from '../images/calendar.svg'
import imglog25 from '../images/select_window_2.svg'
import imglog26 from '../images/assignment.svg'
import imglog27 from '../images/blocks.svg'
import imglog28 from '../images/chat icon.svg'
import imglog29 from '../images/edit icon blue.svg'

const ConsolApplications = () => {
  return (
    <div className='consol-application-page'>
           <div className="consol-card-heading mb-4">
          <h2>Applications</h2>
        </div>
    <div className='consol-applications'>
        <div className="whole-info">
        <div className="row">
        <div className="col-4">
            <div className="heading">
                <div className="icon">
                    <img src={imglog} alt="home icon.svg" className='hedding-icon' />
                </div>
                <span>Home</span>
            </div>
        </div>
        <div className="col-8">
            <div className="text">
                <span>Product</span> 
                <span>Docs</span>
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog1} alt="starsicon.svg" id='icon' />
                </div>
                <span>Assistant</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog3} alt="starsicon.svg" id='icon' />
                </div>
                <span>Inbox</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog4} alt="list.svg" id='icon' />
                </div>
                <span>Directory</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog5} alt="support.svg" id='icon' />
                </div>
                <span>Support</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog6} alt="account.svg" id='icon' />
                </div>
                <span>Profile</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog7} alt="starsicon.svg" id='icon' />
                </div>
                <span>Spaces</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
        </div>
    </div>
        </div>
        <div className="whole-info">
        <div className="row">
        <div className="col-4">
            <div className="heading">
                <div className="icon">
                    <img src={imglog8} alt="haccount_box_FILL0_wght400_GRAD0_opsz24 (1) 2.svg" className='hedding-icon' />
                </div>
                <span>People</span>
            </div>
        </div>
        <div className="col-8">
            <div className="text">
                <span>Product</span> 
                <span>Docs</span>
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog9} alt="profileicon.svg" id='icon' />
                </div>
                <span>Students</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog10} alt="Guardians.svg" id='icon' />
                </div>
                <span>Guardians</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog11} alt="supervisor.svg" id='icon' />
                </div>
                <span>Family</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog12} alt="ballot_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg" id='icon' />
                </div>
                <span>Records</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog13} alt="account.svg" id='icon' />
                </div>
                <span>Rewards</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog14} alt="digital_wellbeing_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg" id='icon' />
                </div>
                <span>Reflections</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
        </div>
    </div>
        </div>
        <div className="whole-info">
        <div className="row">
        <div className="col-4">
            <div className="heading">
                <div className="icon">
                    <img src={imglog15} alt="analytics.svg" className='hedding-icon' />
                </div>
                <span>Analytics</span>
            </div>
        </div>
        <div className="col-8">
            <div className="text">
                <span>Product</span> 
                <span>Docs</span>
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog16} alt="Insights.svg"  id='icon' />
                </div>
                <span>Insights</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog17} alt="browse_activity_2.svg" id='icon' />
                </div>
                <span>Activity</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog18} alt="Streams.svg" id='icon' />
                </div>
                <span>Streams</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog19} alt="Grades.svg" id='icon' />
                </div>
                <span>Grades</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog20} alt="Report.svg" id='icon' />
                </div>
                <span>Report</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog21} alt="leaderboard.svg" id='icon' />
                </div>
                <span>Leaderboard</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog22} alt="rank.svg" id='icon' />
                </div>
                <span>Rank</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
        </div>
    </div>
        </div>
        <div className="whole-info">
        <div className="row">
        <div className="col-4">
            <div className="heading">
                <div className="icon">
                    <img src={imglog23} alt="wysiwyg.svg" className='hedding-icon' />
                </div>
                <span>Engage</span>
            </div>
        </div>
        <div className="col-8">
            <div className="text">
                <span>Product</span> 
                <span>Docs</span>
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog24} alt="calendar.svg" id='icon' />
                </div>
                <span>Calendar</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog25} alt="select_window_2.svg" id='icon' />
                </div>
                <span>Catalog</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog26} alt="assignment.svg" id='icon' />
                </div>
                <span>Gradebook</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog27} alt="support.svg" id='icon' />
                </div>
                <span>Applets</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog28} alt="chat icon.svg" id='icon' />
                </div>
                <span>Channels</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
            <div className="info">
                <div className="info1">
                <div className="icon">
                    <img src={imglog29} alt="edit icon blue.svg" id='icon' />
                </div>
                <span>Boards</span>
                </div>
                <div className="info2">
                    <img src={imglog2} alt="paper.svg" />
                </div>
                
            </div>
        </div>
    </div>
        </div>
    </div>
    </div>
  )
}

export default ConsolApplications