import React, { useEffect, useState } from "react";
import { Form, FormLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../helpers/asset";
import Accordion from 'react-bootstrap/Accordion';
import {
  createOwnerAsyncThunk,
  updateOwnerAsyncThunk,
  createUserRoleAsyncThunk
} from "../redux/pagesSlices/ownerSlice";
import { OwnerSchema } from "./Validation";
import ErrorMassage from "./ErrorMassage.jsx";
import CustomButton from "./customComponents/CustomButton";
import { Formik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
// import formInitialValues from './formInitialValues'
import * as yup from "yup";

const GroupCreateAndUpdate = ({ onHide }) => {
  const [image, setImages] = useState(null);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState()
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs.args?.member;
  const d = useDispatch();

  const authErr = useSelector((state) => state.owner?.errorMessages?.createOwnerAsyncThunk);



  const createOwnerHandler = (data) => {
    if (oldData?.user_id) {
      d(
        updateOwnerAsyncThunk({
          data: {
            name: data.name,
            // username: data.username,
            // roles: data.roles,
            // email: data.email,
            password: data.password,
            connection: "Username-Password-Authentication",
            nickname: data.nickname,
            given_name: data.given_name,
            family_name: data.family_name,
          },
          id: oldData?.user_id,
          callBack: () => {
            toast.success("Updated Group Successfully");
            onHide();
          },
        })
      );
    } else {
      d(
        createOwnerAsyncThunk({
          data,
          callBack: () => {
            toast.success("Create Group Successfully!");
            onHide();
          },
        })
      );
    }
  };
  const closeModel = () => {
    // if (oldData?.callBack) oldData.callBack(oldData[oldData.type])
    d(handleModel({ model: "RightSidebarBase", state: false }));
  };




  // role
  let typesRole = [
    { id: 1, name: "Student" },
    { id: 2, name: "Owner" }
  ]
  const options =
    typesRole &&
    typesRole
      ?.map((e) => {
        return { value: e.id, label: e.name };
      })



  // Define the Yup validation schema
  const OwnerSchemaIn = yup.object().shape({
    name: yup.string().required("name is Required"),
    username: yup.string().required("Username is Required"),
    email: yup.string().email("Invalid email"),

    // password: yup.string().when("email", {
    //   is: (email) => email && email == initialValues.email,
    //   then: yup.string().required("Password is required"),
    //   otherwise: yup.string(),
    // }),

    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .min(8, "Password must be at least 8 characters long"),

    connection: yup.string().required("connection is Required"),
    nickname: yup.string().required("nickname is Required"),
    given_name: yup.string().required("given_name is Required"),
    family_name: yup.string().required("family_name is Required"),
    // roles: yup.array().required("roles is Required"),
  });


  const initialValues = {
    ...(oldData?.user_id
      ? {
        ...oldData,
        name: oldData.name,
        username:oldData?.username,
        email: oldData.email,
        password: oldData?.password,
        connection: "Username-Password-Authentication",
        // connection: oldData?.identities[0]?.connection,
        // roles: oldData?.app_metadata?.roles,
        nickname: oldData.nickname,
        given_name: oldData.given_name,
        family_name: oldData.family_name,
        user_id: undefined,
      }
      : {
        name: "",
        username: "",
        email: "",
        password: "",
        connection: "Username-Password-Authentication",
        roles: "",
        nickname: "",
        given_name: "",
        family_name: "",
        // picture: ""
      }),
  }


  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={createOwnerHandler}
        validationSchema={OwnerSchemaIn}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className="create-course-sibebar">
            <h4 className="heading">
              {oldData?.user_id
                ? "Update Group"
                : "Create Group"}
            </h4>

            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    onChange={handleChange("username")}
                    onBlur={handleBlur("username")}
                    value={values?.username}
                  />
                  <ErrorMassage
                    error={errors.username}
                    visible={touched.username}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values?.email}
                  />
                  <ErrorMassage error={errors?.email} visible={touched?.email} />
                </Form.Group>
              </div>


              <div className="col-lg-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Password"
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values?.password}
                  />
                  <ErrorMassage error={errors?.password} visible={touched?.password} />
                </Form.Group>
              </div>

              {/* <div className="col-lg-12">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Connection</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    placeholder="Enter Connection"
                    onChange={handleChange("connection")}
                    onBlur={handleBlur("connection")}
                    value={values?.connection}
                  />
                  <ErrorMassage error={errors?.connection} visible={touched?.connection} />
                </Form.Group>
              </div> */}

              <div className="col-lg-12">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Roles</Form.Label>
                  <Select
                    options={options}
                    name="roles"
                    onInputChange={(e) => {
                      setSearch(e);
                    }}
                    onChange={(selectedOptions) => {
                      const selectedRoleNames = selectedOptions.map((option) => option.label);
                      setFieldValue("roles", selectedRoleNames);
                    }}
                    value={options.find((option) => option.label === values.roles)}
                    onBlur={handleBlur("roles")}
                    isSearchable={false}
                    isMulti
                  />
                  <ErrorMassage
                    error={errors.roles}
                    visible={touched.roles}
                  />
                </Form.Group>
                {values.roles && (
                  <ul>
                    {values.roles.map((role, index) => (
                      <li key={index}>{role}</li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values?.name}
                  />
                  <ErrorMassage
                    error={errors.name}
                    visible={touched.name}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Nickname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter nickname"
                    onChange={handleChange("nickname")}
                    onBlur={handleBlur("nickname")}
                    value={values?.nickname}
                  />
                  <ErrorMassage
                    error={errors.nickname}
                    visible={touched.nickname}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Given Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Given Name"
                    onChange={handleChange("given_name")}
                    onBlur={handleBlur("given_name")}
                    value={values?.given_name}
                  />
                  <ErrorMassage
                    error={errors.given_name}
                    visible={touched.given_name}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Family Name"
                    onChange={handleChange("family_name")}
                    onBlur={handleBlur("family_name")}
                    value={values?.family_name}
                  />
                  <ErrorMassage
                    error={errors.family_name}
                    visible={touched.family_name}
                  />
                </Form.Group>

              </div>
            </div>



            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Attach Thumbnail</Form.Label>
              <div
                className="img-uploader"
                onClick={() => openContent(setFieldValue)}
              >
               
                  <div className="icon-box">
                    <i className="fa-regular fa-image"></i>
                  </div>
              </div>
              <ErrorMassage error={errors.image} visible={touched.image} />
            </Form.Group> */}

            {/* <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Adress</Accordion.Header>
                <Accordion.Body>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Country"
                      onChange={handleChange("country")}
                      onBlur={handleBlur("country")}
                      value={values?.country}
                    />
                    <ErrorMassage error={errors?.country} visible={touched?.country} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone No</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      onChange={handleChange("number")}
                      onBlur={handleBlur("number")}
                      value={values?.number}
                    />
                    <ErrorMassage error={errors?.number} visible={touched?.number} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      onChange={handleChange("address")}
                      onBlur={handleBlur("address")}
                      value={values?.address}
                    />
                    <ErrorMassage error={errors?.address} visible={touched?.address} />
                  </Form.Group>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Action</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}


            <div className="bottom-btns">
              <button className="btn btn-light"

                onClick={() => closeModel()}
              >
                Cancel
              </button>
              {/* <button
                onClick={() => handleSubmit()}
                className="btn btn-primary"
                type="submit"
              >
                Save
              </button> */}

              <CustomButton
                reducer={"owner"}
                action={oldData?.user_id ? "updateOwnerAsyncThunk" : "createOwnerAsyncThunk"}
                title={"Save"}
                onClick={handleSubmit}
                loadingText={oldData?.user_id ? "Updating..." : "Creating..."}
                className="btn btn-primary"
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default GroupCreateAndUpdate;
