import React from 'react'
import ConsolTable from '../screens/ConsolTable'
import SearchBar from '../screens/SearchBar'

const ConsolTablePage = () => {
  return (
    <div className='consol-table-page'>
        <div className="consol-card-heading mb-3">
          <h2>Notifications</h2>
        </div>
        <SearchBar  text='Not read'/>       
        <ConsolTable/>
    </div>
  )
}

export default ConsolTablePage