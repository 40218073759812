import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {

    
  const data = {
    labels: [
      "January", "February", "March", "April", "May",
      "June", "July", "August", "September", "October",
      "November", "December"
    ],
    datasets: [
      {
        label: "",
        data: [18, 22, 30, 18, 22, 16, 18, 30, 22, 18, 22, 18],
        backgroundColor: "#2181FA",
        borderRadius: 6,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 8, // Set font size to 8px
          },
        },
        grid: {
          display: false, // Remove grid lines for x-axis
        },
      },
      y: {
        ticks: {
          stepSize: 10, // Set steps of 10
          callback: (value) => `${value}`, // Format tick labels
        },
        beginAtZero: true, // Start from 0
        grid: {
          display: true, // Keep grid lines for y-axis
        },
        // Set border color for y-axis to transparent to remove left vertical line
        borderColor: 'transparent',
      },
    },
  };

  return <Bar data={data} options={options} style={{width : '100%'}} className="bar-chart" />;
};

export default BarChart;
