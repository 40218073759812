import React, { useState } from 'react'
import ConsolCards from './ConsolCards';
import ConsolTable2 from './ConsolTable2';
import ConsolApplications from './ConsolApplications';
import ConsolTablePage from './ConsolTablePage';
import ConsolHeader from './ConsolHeader';

const ConsolLinks = () => {
  const [activeTab, setActiveTab] = useState('Highlights');

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  }
  return (
    <div className='consol-dashboard'>
       <>
        <ConsolHeader/>
    <div className='consol-links'>
      <ul className='tags' >

        <li><a href="#" className={`tab-button ${activeTab === 'Highlights' ? 'active' :''}`}
        onClick={() => handleClick('Highlights')}>
          Highlights</a></li>         
       
        <li><a href="#" className={`tab-button ${activeTab === 'Inbox' ? 'active':''}`}
        onClick={() => handleClick('Inbox' )}>Inbox</a></li>

        <li><a href="#" className={`tab-button ${activeTab === 'Directory' ? 'active':''}`}
        onClick={() => handleClick('Directory' )}>Directory</a></li>

        <li><a href="#" className={`tab-button ${activeTab === 'Guide' ? 'active':''}`}
        onClick={() => handleClick('Guide' )}>Guide</a></li>


      </ul>
          </div>
          <div className='tab-content'>
      {activeTab === 'Highlights' && <ConsolCards/> }
      {activeTab === 'Inbox' && <ConsolTablePage/> }
      {activeTab === 'Directory' && <ConsolTable2/> }
      {activeTab === 'Guide' && <ConsolApplications/> }
      </div>
    </>
      </div>
   

  )
}

export default ConsolLinks