import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../../redux/layoutSlices/modelSlice";


export default function PdfCard(item) {
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.contentModel
  );
  const d = useDispatch();
  const handlePdfs = () => {
    if (modelsArgs?.pdfs && modelsArgs?.pdfs?.length) {
      if (modelsArgs?.pdfs?.some((k) => k.id === item.id)) {
        return modelsArgs.pdfs.filter((k) => k.id !== item.id);
      } else {
        return [...modelsArgs?.pdfs, item];
      }
    }
    return [item];
  };
  return (
    <div
      onClick={() => {
        if (modelsArgs?.type === "pdf" || modelsArgs?.type === "pdfs") {
          d(
            handleModel({
              model: "contentModel",
              state: true,
              args: {
                ...modelsArgs,
                ...(modelsArgs?.type === "pdf"
                  ? { pdf: item }
                  : { pdfs: handlePdfs() }),
              },
            })
          );
        }
      }}
      className={`col-lg-4 ${
        modelsArgs?.type === "pdfs"
          ? modelsArgs?.pdfs &&
            modelsArgs?.pdfs?.some((k) => k.id === item.id) &&
            "selected-image"
          : modelsArgs?.type === "pdf" &&
            modelsArgs?.pdf?.id === item.id &&
            "selected-image"
      }`}
    >
      <img
        draggable={false}
        className=""
        src={require("../../../images/pdfimg.png")}
      />
      <h6>{item?.originalName}</h6>
    </div>
  );
}
