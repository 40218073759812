import React from 'react'
import imglog1 from '../images/ringimg.svg'
import imglog2 from '../images/summarize.png'
import imglog3 from '../images/plus.svg'
import imglog4 from '../images/Frame.svg'
import ConsolTable2 from '../screens/ConsolTable2'

const ConsolTable = () => {
  return (
    <div className='consol-table'>
                <table className='table-of-consol'>
<thead>
<tr>
            <th>
            <div className="info">
            <span>Name</span>
        </div>
            </th>
            <th>
            <div className="info">
            <span>Status</span>
        </div>
        </th>
       </tr>
</thead>
         <tbody>
         <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog1} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>Earn</span>
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog2} alt="" className='tableimg'/>
                </div>
                <span>You receive is Trophies</span>
            </div>
        </td>
        <td>
            <div className="info2">
            <span>Earn</span>
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog3} alt="" className='tableimg'/>
                </div>
                <span>Start new servy</span>
            </div>
        </td>
        <td>
            <div className="info3">
            <span>Start</span>
            </div>
   </td>
      </tr>
      <tr>
      <td>
            <div className='infoa'>
            <div className="infoimg">
               <img src={imglog4} alt="" className='tableimg'/>
                </div>
                <span>You got it 30 bronze</span>
            </div>
        </td>
        <td>
            <div className="info4">
            <span>Wrong</span>
            </div>
   </td>
      </tr>

         </tbody>
            </table>
         
    </div>
  )
}

export default ConsolTable