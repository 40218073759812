import React, { useEffect, useState } from "react";
import Logo from ".././images/AllProducts/Group.svg";
import people from ".././images/AllProducts/account_box_FILL0_wght400_GRAD0_opsz24 (1) 1.svg";
import students from ".././images/AllProducts/Student.svg";
import file from ".././images/AllProducts/description_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 2.svg";
import guardian from ".././images/AllProducts/Guardians.svg";
import family from ".././images/AllProducts/Families.svg";
import analytic from ".././images/AllProducts/analytics.svg";
import insight from ".././images/AllProducts/Insights.svg";
import grade from ".././images/AllProducts/Grades.svg";
import stream from ".././images/AllProducts/Streams.svg";
import engage from ".././images/AllProducts/wysiwyg.svg";
import window from ".././images/AllProducts/select_window_2.svg";
import assign from ".././images/AllProducts/assignment.svg";
import topic from ".././images/AllProducts/topic.svg";
import space from ".././images/AllProducts/casino.svg";
import store from ".././images/AllProducts/widgets.svg";
import { redirect_uris } from "../constants";
import { Link } from "react-router-dom";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  createFavouriteAsyncThunk,
  getMyFavouriteRoutesAsyncThunk,
} from "../redux/pagesSlices/favouriteSlice";
import { Spinner } from "react-bootstrap";

const AllProducts = () => {
  const dispatch = useDispatch();
  const [loadingRoute, setLoadingRoute] = useState(null);
  const { favouriteRoutes } = useSelector((state) => state.favourite);
  const customToken = localStorage.getItem("custom-token");

  useEffect(() => {
    dispatch(getMyFavouriteRoutesAsyncThunk({}));
  }, []);

  const handleFavourite = (route_name, route_url) => {
    // setLoadingRoute(route_name);
    dispatch(
      createFavouriteAsyncThunk({
        data: { route_name, route_url },
        callBack: () => {
          // setLoadingRoute(null);
        },
      })
    );
  };

  const isExistsInFavourite = (route_name) => {
    return favouriteRoutes?.some((route) => route.route_name === route_name);
  };

  return (
    <>
      <div className="container">
        <div className="page-title">
          <img src={Logo} alt="Logo" />
          <p>All Products</p>
        </div>

        <div className="people-section">
          <img src={people} alt="People-Icon" />
          <p>People</p>
        </div>

        <div className="table-wrapper">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Favourite</th>
                <th style={{ textAlign: "end" }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <a
                    href={redirect_uris.People + `?token=${customToken}`}
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={students} alt="Students" />
                      <p>Students</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Students history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite("student", redirect_uris.People)
                    }
                  >
                    {loadingRoute === "student" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("student") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/35132281008275-Overview-of-Adding-Importing-and-Archiving-Students-in-Ilmiya"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.People +
                      "guardians" +
                      `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={guardian} alt="Students" />
                      <p>Guardians</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Guardians history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite(
                        "guardians",
                        redirect_uris.People + "guardians"
                      )
                    }
                  >
                    {loadingRoute === "guardians" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("guardians") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/35143245991059-Adding-and-Managing-Guardian-Profiles"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.People + "family" + `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={family} alt="Students" />
                      <p>Family</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Family history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite("family", redirect_uris.People + "family")
                    }
                  >
                    {loadingRoute === "family" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("family") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/35142900638739-How-to-Set-Up-Family-Profiles"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* section-2 */}

        <div className="people-section">
          <img src={analytic} alt="People-Icon" />
          <p>Analytics</p>
        </div>

        <div className="table-wrapper">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Favourite</th>
                <th style={{ textAlign: "end" }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <a
                    href={redirect_uris.Analytics + `?token=${customToken}`}
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={insight} alt="Students" />
                      <p>Insights</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Insights history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite("insights", redirect_uris.Analytics)
                    }
                  >
                    {loadingRoute === "insights" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("insights") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/34146262439187-Gain-Insights"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      "grade" +
                      `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={grade} alt="Students" />
                      <p>Grades</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Grades history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite(
                        "grades",
                        redirect_uris.Analytics + "grade"
                      )
                    }
                  >
                    {loadingRoute === "grades" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("grades") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/34158999480595-Data-Deep-Dive-with-Grades"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      "stream" +
                      `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={stream} alt="Streams" />
                      <p>Streams</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Streams history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite(
                        "streams",
                        redirect_uris.Analytics + "stream"
                      )
                    }
                  >
                    {loadingRoute === "streams" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("streams") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/34181770096531-Real-time-Streams"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Analytics +
                      "leaderboard" +
                      `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={stream} alt="Streams" />
                      <p>LeaderBoards</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>LeaderBoards history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite(
                        "leaderboards",
                        redirect_uris.Analytics + "leaderboard"
                      )
                    }
                  >
                    {loadingRoute === "leaderboards" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("leaderboards") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>
                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/34182080695443-Measure-Impact-with-Leaderboard"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* section-3 */}

        <div className="people-section">
          <img src={engage} alt="engage" />
          <p>Engage</p>
        </div>

        <div className="table-wrapper">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Favourite</th>
                <th style={{ textAlign: "end" }}>Docs</th>
              </tr>
            </thead>

            <tbody>
              {/* <tr>
                                <td>
                                    <a
                                        href={
                                            redirect_uris.Studio +
                                            "discover" +
                                            `?token=${customToken}`
                                        }
                                        target="blank"
                                    >
                                        <div className='image-wrapper'>
                                            <img src={window} alt='Students' />
                                            <p>Discover</p>
                                        </div>
                                    </a>
                                </td>

                                <td>
                                    <p mb-0  >All students history</p>
                                </td>


                                <td style={{ textAlign: 'end' }}>
                                    <div className='file-img'>
                                        <img src={file} alt='File' />
                                    </div>
                                </td>
                            </tr> */}

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Studio +
                      "assignment" +
                      `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={assign} alt="Students" />
                      <p>Assign</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Assignment history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite(
                        "assignment",
                        redirect_uris.Studio + "assignment"
                      )
                    }
                  >
                    {loadingRoute === "assignment" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("assignment") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/35144144506899-Creating-and-Assigning-Student-Assignments"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              {/* <tr>
                                <td>
                                    <div className='image-wrapper'>
                                        <img src={topic} alt='Students' />
                                        <p>Pathways</p>
                                    </div>
                                </td>

                                <td>
                                    <p mb-0  >All students history</p>
                                </td>


                                <td style={{ textAlign: 'end' }}>
                                    <div className='file-img'>
                                        <img src={file} alt='File' />
                                    </div>
                                </td>
                            </tr> */}

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Admin + "space" + `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={space} alt="Students" />
                      <p>Spaces</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Spaces history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite("space", redirect_uris.Admin + "space")
                    }
                  >
                    {loadingRoute === "space" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("space") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/35143480270611-Creating-and-Organizing-Classes"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <a
                    href={
                      redirect_uris.Studio + "apps" + `?token=${customToken}`
                    }
                    target="blank"
                  >
                    <div className="image-wrapper">
                      <img src={store} alt="Students" />
                      <p>Appstore</p>
                    </div>
                  </a>
                </td>

                <td>
                  <p mb-0>Appstore history</p>
                </td>

                <td>
                  <button
                    className="btn btn-link text-dark text-center"
                    onClick={() =>
                      handleFavourite("apps", redirect_uris.Studio + "apps")
                    }
                  >
                    {loadingRoute === "apps" ? (
                      <Spinner animation="border" size="sm" />
                    ) : isExistsInFavourite("apps") ? (
                      <FaStar fontSize={30} />
                    ) : (
                      <CiStar fontSize={30} />
                    )}
                  </button>
                </td>

                <td style={{ textAlign: "end" }}>
                  <div className="file-img">
                    <Link
                      target="_blank"
                      to="https://support.ilmiya.com/hc/en-us/articles/34182523592979-My-Apps"
                    >
                      <img src={file} alt="File" />
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
