import React, { useEffect, useState } from "react";
import { ApiRequests } from "../service/ApiRequests";

export default function FindOrgs() {
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const getOrgs = async () => {
    try {
      setLoading(true);
      const orgs = await ApiRequests.getOrganizationsMembership({ limit: 1 });
      if (orgs?.data?.results?.length > 0) {
        setOrgs(orgs.data.results[0]);
        window.location.href = `/console/${orgs.data.results[0].displayName}/`;
      }
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const consoleValue = pathArray[2];
    if (!consoleValue) getOrgs();
    else setLoading(false);
  }, []);
  if (loading)
    return (
      <div className="error-div">
        <h2>{"Wait!"}</h2>
        <h1>Loading Organizations.</h1>
        <h5 className="error-message">{"The organization not found!."}</h5>
      </div>
    );
  return (
    <div className="error-div">
      <h2>{"404"}</h2>
      <h1>OOPS</h1>
      <h5 className="error-message">{"The organization not found!."}</h5>
    </div>
  );
}
