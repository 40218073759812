import React from 'react'
import imglog6 from '../images/filter_alt.svg'
import imglog7 from '../images/blueclock.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SearchBar = ({text}) => {
  return (
    <div className='searchbar'>
                  <Row>
            <Col md={10}>
                <div className="text">
                    <img src={imglog6} alt="" className='filtericon' />
                    <input type="text" placeholder='Filter students'  style={{outline:'none', border:'none'}}/>
                </div>

            </Col>
            <Col md={2}>
                    <div className="text2">
                    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:'100%'}}>
      <img src={imglog7} alt="" className='blueclock'/><span>{text}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                     </div>
                </Col>
          </Row>
    </div>
  )
}

export default SearchBar