import React from 'react'
import  ConsolApplications from '../screens/ConsolApplications'

const ConsolApplicationsPage = () => {
  return (
    <div className='consol-application-page'>
        <ConsolApplications/>
    </div>
  )
}

export default ConsolApplicationsPage